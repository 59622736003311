<template>
  <div>
    <van-swipe>
      <van-swipe-item>
        <img style="width: 100%" src="@/assets/product/banner-1.jpg" />
      </van-swipe-item>
    </van-swipe>

    <div class="card">
      <div style="display: flex">
        <span class="title" style="flex: 1">
          {{ $t('message')['home.productCenter'] }}
        </span>
      </div>
      <van-grid :column-num="2" :gutter="2">
        <van-grid-item v-for="item in productList" :key="item.id" style="font-size: 0.8em" @click="gotoDetail(item)">
          <van-image style="min-width: 80px; margin-bottom: 5px" fit="contain" lazy-load
            :src="$i18n.locale === 'enUS' ? item.imageUrlEn : item.imageUrl"
            :alt="$i18n.locale === 'enUS' ? item.nameEn : item.name" />
          {{ $i18n.locale === 'enUS' ? item.nameEn : item.name }}
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<script>
import { getLanguage } from '@/utils/languageUtils'
import { getProductList } from '@/api/product'
export default {
  // watch: {
  //   '$i18n.locale': function (val) {
  //     this.changeLanguage(val)
  //   },
  // },
  data () {
    return {
      productList: [],
      params: {
        pageNum: 1,
        pageSize: 1000,
      },
    }
  },
  created () {
    this.getProductList(this.params)
  },
  methods: {
    // 获取产品列表
    getProductList () {
      const toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...',
      })
      getProductList()
        .then((res) => {
          if (res.code === 200) {
            this.productList = res.rows
            // eslint-disable-next-line no-console
            // console.log(res.data)
          } else if (res.code === 404) {
            // 失败
            this.$router.push({
              path: '/404',
              query: { locale: getLanguage(this.$route) },
            })
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            // 失败
            this.$router.push({
              path: '/404',
              query: { locale: getLanguage(this.$route) },
            })
          } else {
            this.$toast.fail(err.message)
          }
        })
        .finally(() => {
          toast.clear()
        })
    },

    gotoDetail (item) {
      this.$router.push({ path: '/product/small-module/detail/' + item.id })
    },
  },
}
</script>

<style lang="less" scoped>
.card {
  padding: 15px 5px 0px 5px;
}

.title {
  font-size: 1.4em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}
</style>
