<template>
  <div class="page-content">
    <div v-if="info" class="product-img">
      <img :src="info.imageUrl" />
    </div>

    <van-divider :style="{ borderColor: '#f00', margin: 0, padding: '10px 0' }" />

    <div v-if="info" class="info-bg">
      <div>
        <div class="product-title">
          {{ $t('message')['product.dialogs.detail.title'] }}
        </div>
        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.name'] }}:
          </span>
          <span class="into-item-info">
            {{ $i18n.locale === 'enUS' ? info.nameEn : info.name }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.type'] }}:
          </span>
          <span class="into-item-info">
            {{ $i18n.locale === 'enUS' ? info.typeEn : info.type }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.chip'] }}:
          </span>
          <span class="into-item-info">
            {{ $i18n.locale === 'enUS' ? info.chipEn : info.chip }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.size'] }}:
          </span>
          <span class="into-item-info">
            {{ $i18n.locale === 'enUS' ? info.sizeEn : info.size }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.bleVersion'] }}:
          </span>
          <span class="into-item-info">
            {{ $i18n.locale === 'enUS' ? info.bleVersionEn : info.bleVersion }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.antenna'] }}:
          </span>
          <span class="into-item-info">
            {{ $i18n.locale === 'enUS' ? info.antennaEn : info.antenna }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.storage'] }}:
          </span>
          <span class="into-item-info">
            {{
                $i18n.locale === 'enUS'
                  ? info.RAMEn + '；' + info.flashEn
                  : info.RAM + '；' + info.flash
            }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.distance'] }}:
          </span>
          <span class="into-item-info">
            {{ $i18n.locale === 'enUS' ? info.distanceEn : info.distance }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.f'] }}:
          </span>
          <span class="into-item-info">
            {{ $i18n.locale === 'enUS' ? info.fEn : info.f }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.sleepElectricity'] }}:
          </span>
          <span class="into-item-info">
            {{
                $i18n.locale === 'enUS'
                  ? info.sleepElectricityEn
                  : info.sleepElectricity
            }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.txAndRx'] }}:
          </span>
          <span class="into-item-info">
            {{ $i18n.locale === 'enUS' ? info.txAndRxEn : info.txAndRx }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.temperatureMin'] }}:
          </span>
          <span class="into-item-info">
            {{
                $i18n.locale === 'enUS'
                  ? info.temperatureMinEn
                  : info.temperatureMin
            }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.temperatureMax'] }}:
          </span>
          <span class="into-item-info">
            {{
                $i18n.locale === 'enUS'
                  ? info.temperatureMaxEn
                  : info.temperatureMax
            }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.powerMax'] }}:
          </span>
          <span class="into-item-info">
            {{ $i18n.locale === 'enUS' ? info.powerMaxEn : info.powerMax }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.sensitivity'] }}:
          </span>
          <span class="into-item-info">
            {{
                $i18n.locale === 'enUS' ? info.sensitivityEn : info.sensitivity
            }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.package'] }}:
          </span>
          <span class="into-item-info">
            {{ $i18n.locale === 'enUS' ? info.packageInfoEn : info.packageInfo }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.pinCount'] }}:
          </span>
          <span class="into-item-info">
            {{ $i18n.locale === 'enUS' ? info.pinCountEn : info.pinCount }}
          </span>
        </div>

        <div class="info-item">
          <span class="into-item-title">
            {{ $t('message')['product.dialogs.support'] }}:
          </span>
          <span class="into-item-info">
            {{
                $i18n.locale === 'enUS'
                  ? info.supportEn
                    ? 'yes'
                    : 'no'
                  : info.support
                    ? '是'
                    : '否'
            }}
          </span>
        </div>

        <!-- 产品特点 -->
        <div>
          <div class="product-title">
            {{ $t('message')['product.dialogs.feature'] }}
          </div>

          <div class="info-item" v-for="item in featureList" :key="item">
            {{ item }}
          </div>
        </div>

        <!-- 产品应用 -->
        <div>
          <div class="product-title">
            {{ $t('message')['product.dialogs.application'] }}
          </div>

          <div class="info-item">
            {{
                $i18n.locale === 'enUS' ? info.applicationEn : info.application
            }}
          </div>
        </div>

        <!-- 备注 -->
        <div v-if="info && info.note">
          <div class="product-title">
            {{ $t('message')['product.dialogs.note'] }}
          </div>

          <div class="info-item">
            {{ $i18n.locale === 'enUS' ? info.noteEn : info.note }}
          </div>
        </div>

        <van-divider :style="{ borderColor: '#f00', margin: 0, padding: '10px 0' }" />

        <!-- 英文资料 -->
        <div v-if="info && info.dataResourceUrlEn && $i18n.locale === 'enUS'">
          <a target="_blank" :href="info.dataResourceUrlEn">
            {{ $t('message')['product.dialogs.download'] }}
          </a>
        </div>

        <!-- 中文资料 -->
        <div v-if="info && info.dataResourceUrl && $i18n.locale !== 'enUS'">
          <a target="_blank" :href="info.dataResourceUrl">
            {{ $t('message')['product.dialogs.download'] }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLanguage } from '@/utils/languageUtils'
export default {
  name: 'DialogsDetail',
  props: {
    info: {
      type: Object,
      default: null,
      require: true,
    },
  },
  computed: {
    featureList: {
      get () {
        if (getLanguage(this.$route) === 'enUS') {
          if (this.info && this.info.featureEn) {
            return this.info.featureEn.split('\n')
          } else {
            return []
          }
        } else {
          if (this.info && this.info.feature) {
            return this.info.feature.split('\n')
          } else {
            return []
          }
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
.page-content {
  padding: 0 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.product-img {
  text-align: center;
}

.product-img img {
  border-radius: 5px;
  border-color: gray;
  border-width: 1px;
  border-style: solid;
  width: 120px;
  height: 120px;
  margin-top: 10px;
}

.product-title {
  color: #333;
  font-weight: bold;
  font-size: 1.2em;
  padding: 10px 0;
}

.info-bg {
  overflow-y: auto;
  flex: 1;
  padding: 0 10px 25px;
}

.info-item {
  padding: 5px 0;
  display: flex;
  align-items: center;
  line-height: 1.5em;
}

.into-item-title {
  color: #666;
}

.into-item-info {
  color: #333;
  flex: 1;
  text-align: right;
}
</style>
