<template>
  <div>
    <van-swipe>
      <van-swipe-item>
        <img style="width: 100%" src="@/assets/product/chips-banner-1.png" />
      </van-swipe-item>
    </van-swipe>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text=""
      :error.sync="showError"
      :error-text="$t('message')['tips.request.failed']"
      @load="onLoad"
    >
      <van-cell
        class="van-cell--clickable"
        v-for="item in list"
        :key="item.id"
        @click="gotoDetail(item)"
      >
        <template #title>
          <span class="cell-big-title">
            {{ $i18n.locale === 'enUS' ? item.nameEn : item.name }}
          </span>
        </template>

        <template #label>
          <!-- 英文 -->
          <van-row v-if="$i18n.locale === 'enUS'">
            <van-col
              :span="info.span ? info.span : 12"
              v-for="info in columnsEn"
              :key="info.title"
              class="cell-line-info"
            >
              <span class="cell-title">{{ info.title }}:</span>
              <span class="cell-info">{{ item[info.dataIndex] }}</span>
            </van-col>
          </van-row>
          <!-- 中文 -->
          <van-row v-else>
            <van-col
              :span="info.span ? info.span : 12"
              v-for="info in columnsZh"
              :key="info.title"
              class="cell-line-info"
            >
              <span class="cell-title">{{ info.title }}:</span>
              <span class="cell-info">{{ item[info.dataIndex] }}</span>
            </van-col>
          </van-row>
        </template>
      </van-cell>
    </van-list>

    <van-popup
      v-model="showDetail"
      position="bottom"
      closeable
      class="detail-box"
    >
      <nordic-detail v-if="showDetail" :info="selectDetailInfo" />
    </van-popup>
  </div>
</template>

<script>
import { getNordicProductList } from '@/api/product'
import NordicDetail from './NordicDetail.vue'
export default {
  components: { NordicDetail },
  data() {
    return {
      list: [],
      loading: false,
      showDetail: false,
      selectDetailInfo: {},
      finished: false,
      showError: false,
      columnsZh: [
        {
          title: '模组类型',
          dataIndex: 'type',
        },
        {
          title: '内置芯片',
          dataIndex: 'chip',
        },
        {
          title: '尺寸(mm)',
          dataIndex: 'size',
        },
        {
          title: '蓝牙版本',
          dataIndex: 'bleVersion',
        },
        {
          title: '休眠工作电流',
          dataIndex: 'sleepElectricity',
        },
        {
          title: 'RAM',
          dataIndex: 'RAM',
        },
        {
          title: 'flash',
          dataIndex: 'flash',
        },
        {
          title: '传输距离(m)',
          dataIndex: 'distance',
          span: 24,
        },
      ],
      columnsEn: [
        {
          title: 'Module type',
          dataIndex: 'typeEn',
        },
        {
          title: 'Inside SOC chip',
          dataIndex: 'chipEn',
        },
        {
          title: 'Size(mm)',
          dataIndex: 'sizeEn',
        },
        {
          title: 'BLE version',
          dataIndex: 'bleVersionEn',
        },
        {
          title: 'Sleep current',
          dataIndex: 'sleepElectricityEn',
        },
        {
          title: 'RAM',
          dataIndex: 'RAMEn',
        },
        {
          title: 'flash',
          dataIndex: 'flashEn',
        },
        {
          title: 'Transmission distance(m)',
          dataIndex: 'distanceEn',
          span: 24,
        },
      ],
      params: {
        pageNum: 1,
        pageSize: 1000,
      },
    }
  },
  methods: {
    onLoad() {
      this.loading = true
      getNordicProductList(this.params)
        .then((res) => {
          // eslint-disable-next-line no-console
          console.error(res)
          if (res.code === 200) {
            this.list = res.rows
          } else {
            this.showError = true
          }
        })
        .catch((/*err*/) => {
          this.showError = true
        })
        .finally(() => {
          this.loading = false
          this.finished = true
        })
    },

    gotoDetail(item) {
      // eslint-disable-next-line no-console
      console.error(item)
      this.showDetail = true
      this.selectDetailInfo = item
    },
  },
}
</script>

<style scoped>
.cell-line-info {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 3px 0;
}

.cell-big-title {
  color: #333;
  font-weight: bold;
}

.cell-title {
  color: #666;
}

.cell-info {
  color: #333;
}

.van-cell--clickable:active {
  background-color: rgba(169, 190, 200, 0.5);
}

.detail-box {
  height: calc(100% - 50px);
}
</style>
